import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    OutlinedInput,
    Snackbar,
    Switch,
    Typography
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ptBR } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import UserContext from "../../context/UserContext";
import { downloadNotice, insertNotice, updateNotice } from "../../services/notice";
import { Title } from '../../styles';
import { colors } from '../../theme';

const NoticesForm = () => {
    const navigate = useNavigate();
    const { state, setState } = useContext(UserContext);
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        message: '',
        type: 'success'
    });
    const [erros, setErrors] = useState({
        titulo: false,
        descricao: false,
        data_inicio: false,
        data_fim: false,
        file: false
    });
    const hiddenFileInput = useRef(null);

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setFile(fileUploaded);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notice = {
            titulo: e.target.titulo.value,
            descricao: e.target.descricao.value,
            inicio: e.target.data_inicio.value,
            fim: e.target.data_fim.value,
            restrito: e.target.restrito.checked,
            imagem: file
        };

        if(!validationForm(notice)){
            notice.inicio = notice.inicio.replaceAll("/", '');
            notice.fim = notice.fim.replaceAll("/", '');

            const data = new FormData();
            data.append("titulo", notice.titulo);
            data.append("descricao", notice.descricao);
            data.append("inicio", notice.inicio);
            data.append("fim", notice.fim);
            data.append("restrito", notice.restrito);
            if(notice.imagem !== null){
                data.append("imagem", notice.imagem);
            }

            setState({ ...state, loading: true });
            saveNotice(data).then((value) => {
                setState({ ...state, loading: false });
                if(value.status === 200){
                    setMessage({
                        open: true,
                        message: 'Dados salvos com sucesso!',
                        type: 'success'
                    });
                    
                }else{
                    setMessage({
                        open: true,
                        message: 'Ocorreu um erro ao salvar, tente novamente mais tarde!',
                        type: 'error'
                    });
                }
            }).catch(() => {
                setState({ ...state, loading: false });
                setMessage({
                    open: true,
                    message: 'Ocorreu um erro ao salvar, tente novamente mais tarde!',
                    type: 'error'
                });
            });
        }

        return false;
    }

    const saveNotice = notice => {
        if(state.notice_edit){
            return updateNotice(state.notice_edit.id, notice);    
        }
        return  insertNotice(notice);
    }

    const validationForm = (notice) => {
        const validFiles = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        let formError = {
          titulo: (notice.titulo.trim().length === 0),
          descricao: (notice.descricao.trim().length === 0),
          data_inicio: (notice.inicio == null || notice.inicio.trim().length === 0),
          data_fim: (notice.fim == null || notice.fim.trim().length === 0),
          file: !validFiles.includes(notice.imagem.type)
        };

        setErrors({...formError});
        return formError.titulo || formError.descricao || formError.data_inicio || formError.data_fim || formError.file;
    }

    const formartDate = date => {
        const day = date.substring(0, 2);
        const month = date.substring(2, 4);
        const year = date.substring(4, 8);

        return `${day}/${month}/${year}`;
    }

    useEffect(() => {

        const roteEdit = window.location.href.includes('editar-aviso');

        if(roteEdit && state.notice_edit === null){
            navigate(`/area-administrativa/lista-avisos`);
        } else if (state.notice_edit !== null){
            downloadNotice(state.notice_edit.id).then(response => {
                const contentType = response.headers["content-type"];
                const newBlob = new Blob([response.data], { type: contentType });
                const data = window.URL.createObjectURL(newBlob);
                
                let image = document.createElement("img");
                image.src = data;
                image.alt = state.notice_edit.arquivo;
                let div = document.getElementById("notice-img-section");
                div.appendChild(image);

                image.addEventListener('click', function handleClick(event) {
                    window.open(data);
                });
            });
        }

        setState({
            ...state,
            breadcrumb: [
              {
                text: "Home",
                link: "/",
              },
              { text: "Área Administrativa", link: "/area-administrativa" },
              { text: "Lista de Avisos", link: "/area-administrativa/lista-avisos" },
              { text: "Cadastrar Aviso" },
            ],
        });

    }, []);

    return (
        <Grid container spacing={2}>
            <Snackbar open={message.open} autoHideDuration={15000} 
                onClose={() => {setMessage({...message, open: true});}} 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Alert severity={message.type}>
                    <AlertTitle>{message.message}</AlertTitle>
                </Alert>
            </Snackbar>

            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <Accordion sx={{ margin: '1em 0' }} expanded={true}>
                    <AccordionSummary sx={{ padding: '0 1em', fontWeight: 500 }}>
                        <Title fontSize='18px' color={colors.darkGray} padding={0} mb='1em'>
                            Cadastrar Aviso
                        </Title>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xl={12} md={12} xs={12}>
                                    <FormControl variant="outlined" sx={{ width: '100%', marginRight: '1em' }} error={erros.titulo}>
                                        <OutlinedInput placeholder="Título" id="titulo" defaultValue={state.notice_edit ? state.notice_edit.titulo : ''}/>
                                        {erros.titulo ? <FormHelperText id="component-error-text">Por favor informe o título.</FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xl={12} md={12} xs={12}>
                                    <FormControl variant="outlined" sx={{ width: '100%', marginRight: '1em' }} error={erros.descricao}>
                                        <OutlinedInput
                                                id="descricao"
                                                placeholder="Descrição"
                                                multiline
                                                rows={4}
                                                defaultValue={state.notice_edit ? state.notice_edit.descricao : ''}
                                        />
                                        {erros.descricao ? <FormHelperText id="component-error-text">Por favor informe a descrição.</FormHelperText> : null}
                                    </FormControl>
                                </Grid>

                                <Grid item xl={4} md={4} xs={4}>
                                    <FormControlLabel
                                        value="true"
                                        control={<Switch color="primary" id="restrito" defaultChecked={state.notice_edit ? state.notice_edit.restrito : ''}/>}
                                        label="Aviso restrito"
                                        labelPlacement="start"/>
                                </Grid>
                                
                                <Grid item xl={4} md={4} xs={4}>
                                    <FormControl variant="outlined" sx={{ width: '100%'}} error={erros.data_inicio}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                                            <MobileDatePicker  
                                            defaultValue={state.notice_edit ? dayjs(formartDate(state.notice_edit.inicio), 'DD/MM/YYYY') : ''}
                                            slotProps={{textField: {id: 'data_inicio'}, actionBar: {actions: ['clear', 'cancel', 'accept']}}}
                                            minDate={dayjs()}
                                            label="Data de início"
                                            format="DD/MM/YYYY"/>
                                            {erros.data_inicio ? <FormHelperText id="component-error-text">Por favor informe a data início.</FormHelperText> : null}
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>

                                <Grid item xl={4} md={4} xs={4}>
                                    <FormControl variant="outlined" sx={{ width: '100%'}} error={erros.data_fim}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="pt-br">
                                            <MobileDatePicker 
                                            defaultValue={state.notice_edit ? dayjs(formartDate(state.notice_edit.fim), 'DD/MM/YYYY') : ''}
                                            slotProps={{textField: {id: 'data_fim'}, actionBar: {actions: ['clear', 'cancel', 'accept']}}}
                                            minDate={dayjs()}
                                            label="Data de fim"
                                            format="DD/MM/YYYY"/>
                                            {erros.data_fim ? <FormHelperText id="component-error-text">Por favor informe a data fim.</FormHelperText> : null}
                                        </LocalizationProvider>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xl={12} md={12} xs={12}>
                                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />} style={{float: 'left'}}>
                                        Upload file
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                                            onChange={handleChange}
                                            ref={hiddenFileInput}
                                            style={{clipPath: 'inset(50%)', width: 1}} // Make the file input element invisible
                                        />
                                    </Button>
                                    <span style={{marginLeft: '10px', float: 'left'}}>{file != null ? <p>Uploaded file: {file.name}</p>:null}</span>
                                </Grid>
                                <Grid item xl={12} md={12} xs={12}>
                                    {erros.file && <Typography variant="caption" display="block" color="#d32f2f" align="left">
                                            Tipo de arquivo incorreto, por favor selecione um arquivo no formato png, jpg, jpeg ou pdf.
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xl={12} md={12} xs={12}>
                                    <div id="notice-img-section" style={{maxWidth: '100%', maxHeight: '100%', backgroundRepeat: 'no-repeat'}}></div>
                                </Grid>
                            </Grid>

                            <Button
                                style={{
                                    marginLeft: 'auto',
                                    marginTop: '3em',
                                    right: '0px',
                                    display: 'flex',
                                    backgroundColor: colors.gray,
                                    width: '200px'
                                }}
                                type='submit'>
                                SALVAR
                            </Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}

export default NoticesForm;