import { api } from "./api";

async function fetchFile(type, id, fileName, token) {
      const response = await api.get(`download/${type}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const contentType = response.headers["content-type"];
      const newBlob = new Blob([response.data], { type: contentType });
      const data = window.URL.createObjectURL(newBlob);

      if (type === "documento") {
        let link = document.createElement("a");
        link.href = data;
        link.download = fileName;
        link.click();
      } else {
        if (contentType.includes("pdf")) {
          window.open(data, "_blank");
          return "pdf"
        } else {
          let div = document.getElementById("img-section"); 
          div.innerHTML = "";

          let image = document.createElement("img");
          image.src = data;
          image.alt = fileName;
          image.style.width = "100%";
          image.style.height = "100%";
          image.style.backgroundRepeat = "no-repeat";
          div.appendChild(image);
        }
      };
}

export { fetchFile };
