import ArrowLeftOutlined from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlined from '@mui/icons-material/ArrowRightOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SendIcon from '@mui/icons-material/Send';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
    Alert,
    AlertTitle,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { useContext, useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router";
import UserContext from "../../context/UserContext";
import { deleteBankSlip, downloadBankSlip, getBankSlipList, insertBankSlip, sendEmailBankSlipUH, separateFilesBankSlip } from "../../services/bankslip";
import {
    Input,
    SectionTitle,
    Text
} from '../../styles';
import { colors } from '../../theme';

const BankSlipList = () => {
    const { state, setState } = useContext(UserContext);
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const hiddenFileInput = useRef(null);
    const [monthYear, setMonthYear] = useState(null);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [bankslipList, setBankslipList] = useState(null);
    const [bankslipDelete, setBankslipDelete] = useState(null);
    const [bankslipSendEmail, setBankslipSendEmail] = useState(null);
    const [bankslipLog, setBankslipLog] = useState(null);
    const [pages, setPageSelected] = useState({
        pageSelected: 1,
        totalPages: 1
    });
    const [message, setMessage] = useState({
        open: false,
        message: '',
        type: 'success'
    });
    const [erros, setErrors] = useState({
        file: false
    });

    const date = new Date();
    const month = date.getMonth()+1 > 9 ? date.getMonth()+1: `0${date.getMonth()+1}`;
    const my = `${date.getFullYear()}-${month}`;

    const fileChange = (event) => {
        const fileUploaded = event.target.files[0];
        setFile(fileUploaded);
    }

    const formattedValue = (value) => {
        const my = value.replace(/[^0-9]/g, '');
        const y = my.slice(0, 4);
        let m = my.slice(4, 6);
        return `${m}${y}`;
    };

    const handleInputDateChange = (e) => {
        const { value } = e.target;
    
        const newV = formattedValue(value);
    
        if (newV.length === 6) {
          setMonthYear(newV);
        }
    };

    const getListBankSlip = () => {
        getBankSlipList(pages.pageSelected).then(({data}) => {
            setBankslipList(data);

            let totalPages = Math.ceil((data.total / 10));
            totalPages = totalPages < 1 ? 1 : totalPages;
            setPageSelected({...pages, totalPages})
        });
    }

    const selectPage = (pageSelected) => {
        setPageSelected({...pages, pageSelected});

        getBankSlipList(pageSelected).then(({data}) => {
            setBankslipList(data);
        });
    }

    const listPage = () => {
        const listSelect = [<MenuItem value="1">1</MenuItem>];

        if(pages.totalPages <= 1)
            return listSelect;

        for(let i = 2; i <= pages.totalPages; i++){
            listSelect.push((
                <MenuItem value={i}>{i}</MenuItem>
            ));
        }
        return listSelect;
    }


    const handleSave = () => {
        const data = new FormData();
        data.append("referencia", monthYear);
        if(file !== null){
            data.append("imagem", file);
        }

        if(!validationForm()){
            setState({ ...state, loading: true });
            insertBankSlip(data).then(() => {
                setState({ ...state, loading: false });
                setMessage({
                    open: true,
                    message: 'Dados salvos com sucesso!',
                    type: 'success'
                });

                setPageSelected({...pages, pageSelected: 1});
                getListBankSlip();
                setOpen(false);
            }).catch((err) => {
                setState({ ...state, loading: false });

                let errorMessage = "Ocorreu um erro ao salvar, tente novamente mais tarde!";

                if(err.response.data.message){
                    errorMessage = err.response.data.message
                }

                setMessage({
                    open: true,
                    message: errorMessage,
                    type: 'error'
                });
            });
        }
    }

    const validationForm = () => {
        const validFiles = ['application/pdf'];

        let formError = {
          file: !validFiles.includes(file?.type)
        };

        setErrors({...formError});
        return formError.file;
    }

    const handleDelete = () => {
        setState({ ...state, loading: true });
        setOpenDialog(false);
        
        deleteBankSlip(bankslipDelete.id).then(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: 'Registro excluído com sucesso!',
                type: 'success'
            });
            setPageSelected({...pages, pageSelected: 1})
            getListBankSlip();
        }).catch((err) => {
            setState({ ...state, loading: false });

            let errorMessage = "Ocorreu um erro ao excluir o registro, tente novamente mais tarde!";

            if(err.response.data.message){
                errorMessage = err.response.data.message
            }

            setMessage({
                open: true,
                message: errorMessage,
                type: 'error'
            });
        });
    }

    const handleClickOpenDelete = (bankslip) => {
        setDialogType('DELETE');
        setBankslipDelete(bankslip);
        setOpenDialog(true);
    };

    const handleClickSendEmail = (bankslip) => {
        setDialogType('SENDEMAIL');
        setOpenDialog(true);
        setBankslipSendEmail(bankslip);
    };

    const downloadPDF = (bankslip) => {
        downloadBankSlip(bankslip.id).then(response => {
            const newBlob = new Blob([response.data], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);

            window.open(data);
        }).catch((err) => {
            setState({ ...state, loading: false });

            let errorMessage = "Ocorreu um erro ao abrir o arquivo, tente novamente mais tarde!";

            if(err.response.data.message){
                errorMessage = err.response.data.message
            }

            setMessage({
                open: true,
                message: errorMessage,
                type: 'error'
            });
        });
    }

    const handleSeparateFiles = (id) => {
        setState({ ...state, loading: true });

        separateFilesBankSlip(id).then(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `Arquivos separados com sucesso!`,
                type: 'success'
            });
            getListBankSlip();
        }).catch((err) => {
            setState({ ...state, loading: false });

            let errorMessage = "Ocorreu um erro ao separar os arquivos!";

            if(err.response.data.message){
                errorMessage = err.response.data.message
            }

            setMessage({
                open: true,
                message: errorMessage,
                type: 'error'
            });
        });
    }

    useEffect(() => {
        setMonthYear(formattedValue(my));
        setState({
            ...state,
            breadcrumb: [
              {
                text: "Home",
                link: "/",
              },
              { text: "Área Administrativa", link: "/area-administrativa" },
              { text: "Lista de Arquivos de Boletos" },
            ],
        });

        getListBankSlip();
    }, []);

    const DialogUpload = () =>(
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Upload do arquivo com os boletos
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                    <Text>Mês de referência</Text>
                    <div style={{ marginLeft: isMobile && 'auto' }}>
                    <Input
                        onChange={(e) => handleInputDateChange(e)}
                        placeholder={`Ex: ${my}`}
                        width='100%'
                        color={colors.primary}
                        defaultValue={my}
                        type='month'
                    />
                    </div>
                </Grid>

                <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                    <Text>Selecionar Arquivo</Text>
                        <Button 
                            component="label" 
                            variant="contained" className="button-primary-default" startIcon={<CloudUploadIcon />} 
                            style={{float: 'left', marginTop: '14px', width: '100%'}}>
                            Upload de Arquivo
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={fileChange}
                                ref={hiddenFileInput}
                                style={{clipPath: 'inset(50%)', width: 1}}
                            />
                        </Button>
                    <Text style={{maxHeigh: '20px', width: '100%', marginTop: '10px', overflow: 'auto'}}>{file != null ? <>Uploaded file: {file.name}</>:null}</Text>
                </Grid>
                
                <Grid item xl={12} md={12} xs={12}>
                    {erros.file && <Typography variant="caption" display="block" color="#d32f2f" align="left">
                            Tipo de arquivo incorreto, por favor selecione um arquivo no formato pdf.
                        </Typography>
                    }
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} autoFocus>
            Fechar
          </Button>
          <Button 
            onClick={() => handleSave()}
            style={{
                backgroundColor: colors.gray,
                width: '100px'
            }}
            disabled={!file}
          >SALVAR</Button>
        </DialogActions>
    </Dialog>);

    const getChipStatus = (label, check, color) => {
        if(check !== null){
            return (
                <Chip label={label}  size="small" 
                    color={color}
                    icon={<CheckCircleIcon style={{fontSize: '12px'}}/>}
                    style={{fontSize: '12px', marginRight: '5px', marginBottom: '2px'}}/>
            );
        }else{
            return (
                <Chip label={label}  size="small"
                    icon={<CancelIcon style={{fontSize: '12px'}}/>}
                    style={{fontSize: '12px', marginBottom: '4px', marginRight: '5px'}}/>
            );
        }
    }

    const handlerSendEmail = () => {
        setOpenDialog(false);
        
        sendEmailBankSlipUH(0, dayjs(bankslipSendEmail.referencia, 'DDMMYYYY').format('MMYYYY')).then(() => {
            setState({ ...state, loading: false });
            setMessage({
                open: true,
                message: `E-mails enviados com sucesso!`,
                type: 'success'
            });
            getListBankSlip();
        }).catch((err) => {
            setState({ ...state, loading: false });

            let errorMessage = "Ocorreu um erro ao enviar os e-mails!";

            if(err.response.data.message){
                errorMessage = err.response.data.message
            }

            setMessage({
                open: true,
                message: errorMessage,
                type: 'error'
            });
        });
    }

    const handleClickLog = item => {
        setDialogType('LOG');
        setBankslipLog(item);
        setOpenDialog(true);
    }

    return (
        <Grid container spacing={2}>
            <Snackbar open={message.open} autoHideDuration={15000} 
                onClose={(event) => setMessage({...message, open: false})} 
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
                }}>
                <Alert severity={message.type}>
                    <AlertTitle>{message.message}</AlertTitle>
                </Alert>
            </Snackbar>
            <Grid item xs={12} md={12} style={{textAlign: 'center', marginTop: '30px'}}>
                <SectionTitle>Lista de Arquivos de Boletos</SectionTitle>
            </Grid>

            <Grid item xs={12} md={12} style={{marginTop: '40px'}}>
                <Button component="label" variant="contained" 
                    className="button-primary-default" 
                    startIcon={<CloudUploadIcon />} 
                    style={{float: 'left', marginTop: '14px'}} 
                    onClick={() => setOpen(true)}>
                    Upload de arquivo
                </Button>
                {DialogUpload()}
            </Grid>

            <Grid item xs={12} md={12}>
                <TableContainer style={{marginTop: '20px'}}>
                    <Table sx={{ minWidth: 650 }} className='table-full-width'>
                        <TableHead>
                        <TableRow>
                            <TableCell align="center">Mês de referência</TableCell>
                            <TableCell align="center">Arquivo</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Ação</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {bankslipList !== null && bankslipList.resultado.map(item => 
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="center">
                                        {dayjs(item.referencia, 'DDMMYYYY').format('MM/YYYY')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.arquivo}
                                    </TableCell>
                                    <TableCell align="center">
                                        {getChipStatus('Separado', item.divisao, 'success')}
                                        {getChipStatus('Enviado', item.envio, 'success')}
                                        {item.log !== null && getChipStatus('Erro', item.log, 'error')}
                                    </TableCell>
                                    <TableCell align="left">
                                        <span className='icon-table' title="Download PDF" onClick={() => downloadPDF(item)}><DownloadIcon /></span>
                                        {item.divisao === null && <span className='icon-table' title="Separar Boletos" onClick={() => handleSeparateFiles(item.id)}><LibraryBooksIcon /></span>}
                                        {item.divisao !== null &&
                                            <span className='icon-table' title="Listar Boletos" >
                                                <ViewListIcon onClick={() => navigate(`/area-administrativa/lista-boletos/${item.id}`)}/>
                                            </span>
                                        }
                                        <span className='icon-table' title="Excluir" onClick={() => handleClickOpenDelete(item)}><DeleteForeverIcon /></span>

                                        {item.divisao !== null && !item.envio &&
                                            <span className='icon-table' title="Enviar E-mails" onClick={() => handleClickSendEmail(item)}><SendIcon /></span>
                                        }
                                        {(item.divisao !== null || item.envio !== null || item.log !== null) &&
                                            <span className='icon-table' title="Log do registro" onClick={() => handleClickLog(item)}><OpenInNewIcon /></span>}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialogType === 'DELETE' && `Deseja realmente excluir o registro?`}
                        {dialogType === 'SENDEMAIL' && `Deseja realmente enviar os e-mails de boleto?`}
                        {dialogType === 'LOG' && `Log do registro`}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogType === 'DELETE' &&
                            <Typography variant="h6" gutterBottom>
                                {bankslipDelete ? dayjs(new Date(bankslipDelete.referencia)).format('MM/YYYY') : ''} - 
                                {bankslipDelete ? bankslipDelete.arquivo : ''}
                            </Typography>}

                        {dialogType === 'SENDEMAIL' && 
                            <Typography variant="h6" gutterBottom>
                                Deseja realmente enviar todos os e-mails referente ao mês {dayjs(bankslipSendEmail.referencia, 'DDMMYYYY').format('MM/YYYY')}?
                            </Typography>}


                        {dialogType === 'LOG' && 
                            <>
                                {bankslipLog.divisao && <div style={{marginBottom: '10px'}}><strong>Data separação do arquivo: </strong> {bankslipLog.divisao}</div>}
                                {bankslipLog.envio && <div style={{marginBottom: '10px'}}><strong>Data de envio: </strong> {bankslipLog.envio}</div>}
                                {bankslipLog.log && <div><strong>Erro: </strong> {bankslipLog.log}</div>}
                            </>}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    {dialogType === 'DELETE' && <Button onClick={() => handleDelete()}>Excluir</Button>}
                    {dialogType === 'SENDEMAIL' && <Button onClick={() => handlerSendEmail()}>Enviar</Button>}
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid item xs={12} md={12} style={{marginBottom: '100px', textAlign: 'center'}}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} style={{display: 'block'}}>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === 1} onClick={() => {selectPage(pages.pageSelected - 1)}}
                        style={{margin: '10px'}}>
                        <ArrowLeftOutlined />
                    </Button>
                    <Select id="demo-customized-select" value={pages.pageSelected} onChange={(event) => {selectPage(parseInt(event.target.value))}}>
                        {bankslipList && listPage().map((item) => item)}
                    </Select>
                    <Button variant="outlined" size="small" disabled={pages.pageSelected === pages.totalPages} onClick={() => {selectPage(pages.pageSelected + 1)}}
                        style={{margin: '10px'}}>
                        <ArrowRightOutlined />
                    </Button>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default BankSlipList;